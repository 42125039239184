import React, { FunctionComponent } from "react";
import { BsPinMapFill } from "react-icons/bs";
import { AiOutlineMail, AiOutlineInstagram } from "react-icons/ai";
import "./contact-us.css";

interface ContactUsProps {
  scrollRef: any;
}

const ContactUs: FunctionComponent<ContactUsProps> = (props) => {
  const { scrollRef } = props;
  //address, phone, email
  return (
    <div className="contact-us-container" ref={scrollRef}>
      <p className="contact-us-header">Contact Us</p>
      <div className="contact-us-content-container">
        <div className="contact-us-content">
          <div className="contact-us-icon-circle">
            <AiOutlineInstagram className="contact-us-icon" size={30} />
          </div>
          <p className="contact-us-name">Instagram</p>
          <p className="contact-us-description">peerpsych</p>
        </div>
        <div className="contact-us-content">
          <div className="contact-us-icon-circle">
            <BsPinMapFill className="contact-us-icon" size={30} />
          </div>
          <p className="contact-us-name">Address</p>
          <p className="contact-us-description">P.O. box coming soon</p>
        </div>
        <div className="contact-us-content">
          <div className="contact-us-icon-circle">
            <AiOutlineMail className="contact-us-icon" size={30} />
          </div>
          <p className="contact-us-name">Email</p>
          <p className="contact-us-description">peerpsych.llc@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import { FunctionComponent } from "react";
import logo from "../../logo.png";
import "./nav-bar.css";

interface NavBarProps {
  ourAppsRef: any;
  contactUsRef: any;
}

const NavBar: FunctionComponent<NavBarProps> = (props) => {
  const { ourAppsRef, contactUsRef } = props;

  const handleOurAppsClick = () => {
    ourAppsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleContactUsClick = () => {
    contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="nav-bar-container">
      <div className="logo-container">
        <img src={logo} alt="logo" className="logo" />
        <p className="logo-text">PeerPsych</p>
      </div>
      <div className="links">
        <p className="link-text" onClick={handleOurAppsClick}>
          Our Apps
        </p>
        <p className="link-text" onClick={handleContactUsClick}>
          Contact Us
        </p>
      </div>
    </div>
  );
};

export default NavBar;

import React, { FunctionComponent } from "react";
import normalIcon from "../../normal.png";
import { BsWrenchAdjustableCircle } from "react-icons/bs";
import "./our-apps.css";

interface OurAppsProps {
  scrollRef: any;
}

const OurApps: FunctionComponent<OurAppsProps> = (props) => {
  const { scrollRef } = props;
  return (
    <div className="our-apps-container" ref={scrollRef}>
      <p className="our-apps-header">Our Apps</p>
      <div className="our-apps-content-container">
        <div className="our-apps-content our-apps-content-left">
          <img src={normalIcon} alt="logo" className="our-apps-picture" />
          <p className="our-apps-name">Norma?</p>
          <p className="our-apps-bio">
            Polling app for basic thoughts and speculations. Available on the
            Apple App Store now
          </p>
        </div>
        <div className="our-apps-content our-apps-content-right">
          <BsWrenchAdjustableCircle
            size={200}
            className="our-apps-coming-soon"
          />
          <p className="our-apps-name">Coming Soon</p>
          <p className="our-apps-bio">Stay tuned for future releases!</p>
        </div>
      </div>
    </div>
  );
};

export default OurApps;

import { useRef } from "react";
import "./App.css";
import ContactUs from "./components/contact-us/contact-us.component";
import Introduction from "./components/introduction/introduction.component";
import NavBar from "./components/nav-bar/nav-bar.component";
import OurApps from "./components/our-apps/our-apps.component";

function App() {
  const ourAppsRef = useRef(null);
  const contactUsRef = useRef(null);

  return (
    <div className="container">
      <NavBar ourAppsRef={ourAppsRef} contactUsRef={contactUsRef} />
      <Introduction />
      <OurApps scrollRef={ourAppsRef} />
      <ContactUs scrollRef={contactUsRef} />
    </div>
  );
}

export default App;

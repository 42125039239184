import React, { FunctionComponent } from "react";
import logoFull from "../../logoFull.png";
import "./introduction.css";

const Introduction: FunctionComponent = (props) => {
  return (
    <div className="introduction-section">
      <div className="introduction-left">
        <p className="introduction-header">
          New Community Fueled Approach to Psychology
        </p>
        <p className="introduction-bio">
          Our company is on a mission to promote mental wellness by providing a
          vibrant and engaging social experience that is both entertaining and
          therapeutic
        </p>
      </div>
      <div className="introduction-right">
        <img src={logoFull} alt="logo" className="introduction-logo" />
      </div>
    </div>
  );
};

export default Introduction;
